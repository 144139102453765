// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/entry.client.tsx"
);
import.meta.hot.lastModified = "1730584816000";
}
// REMIX HMR END

import { RemixBrowser } from "@remix-run/react";
// import { StrictMode } from "react";
import { hydrate } from "react-dom";
import i18n from "./i18n";
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { getInitialNamespaces } from "remix-i18next";

// async function hydrateRoot() {
//   await i18next
//     .use(initReactI18next) // Tell i18next to use the react-i18next plugin
//     .use(LanguageDetector) // Setup a client-side language detector
//     .use(Backend) // Setup your backend
//     .init({
//       ...i18n, // spread the configuration
//       // This function detects the namespaces your routes rendered while SSR use
//       ns: getInitialNamespaces(),
//       backend: { loadPath: "/locales/{{lng}}/{{ns}}.json" },
//       detection: {
//         // Here only enable htmlTag detection, we'll detect the language only
//         // server-side with remix-i18next, by using the `<html lang>` attribute
//         // we can communicate to the client the language detected server-side
//         order: ["htmlTag"],
//         // Because we only use htmlTag, there's no reason to cache the language
//         // on the browser, so we disable it
//         caches: [],
//       },
//     });

//   hydrate(
//     <I18nextProvider i18n={i18next}>
//       {/* <StrictMode> */}
//       <RemixBrowser />
//       {/* </StrictMode> */}
//     </I18nextProvider>,
//     document
//   );
// }
if (!i18next.isInitialized)
  // prevent i18next to be initialized multiple times
  i18next
    .use(initReactI18next) // Tell i18next to use the react-i18next plugin
    .use(LanguageDetector) // Setup a client-side language detector
    .use(Backend) // Setup your backend
    .init({
      ...i18n,
      backend: { loadPath: "/locales/{{lng}}/{{ns}}.json" },
      // This function detects the namespaces your routes rendered while SSR use
      // and pass them here to load the translations
      ns: getInitialNamespaces(),
      detection: {
        // Here only enable htmlTag detection, we'll detect the language only
        // server-side with remix-i18next, by using the `<html lang>` attribute
        // we can communicate to the client the language detected server-side
        order: ["htmlTag"],
        // Because we only use htmlTag, there's no reason to cache the language
        // on the browser, so we disable it
        caches: [],
      },
    })
    .then(() => {
      // then hydrate your app wrapped in the I18nextProvider
      return hydrate(
        <I18nextProvider i18n={i18next}>
          <RemixBrowser />
        </I18nextProvider>,
        document
      );
    });
// if (window.requestIdleCallback) {
//   window.requestIdleCallback(hydrateRoot);
// } else {
//   // Safari doesn't support requestIdleCallback
//   // https://caniuse.com/requestidlecallback
//   window.setTimeout(hydrate, 1);
// }
