// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/i18n.ts"
);
import.meta.hot.lastModified = "1719684841000";
}
// REMIX HMR END

export default {
  supportedLngs: ["en", "es", "pt"],
  fallbackLng: "es",
  defaultNS: "common",
  react: { useSuspense: false },
};
